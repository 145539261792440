@import '../themeCommon.module.less';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 56.25%;

  .backVideo {
    background-color: black;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .iconDiv {
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    .icon {
      color: white;
      cursor: pointer;
      height: 50px;
      width: 50px;
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;