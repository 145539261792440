@import '../../themeCommon.module.less';

.banner {
  .commonImageBanner;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 2880 / 1400 !important;
  min-height: unset !important;
}

.container {
  aspect-ratio: 2880 / 1400;
  min-height: unset !important;
  overflow: hidden;
  .commonImageBanner;
  position: relative;
  .bannerLinear {
    aspect-ratio: 2880 / 1400 !important;
    min-height: unset !important;
  }
  &.isButtonBackground {
    .commonImageBackground;
    .banner {
      .commonImageBanner;
      align-items: center;
    }

    .content {
      align-items: center;
    }
  }

  .image {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .content {
    .commonWidth;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .title {
      color: white;
      font-weight: 500;
      font-size: 25px;
      @media (min-width: 376px) {
        font-size: 25px;
      }
      @media (min-width: 576px) {
        font-size: 30px;
      }
      @media (min-width: 768px) {
        font-size: 30px;
      }
      @media (min-width: 992px) {
        font-size: 60px;
      }
      @media (min-width: 1200px) {
        font-size: 60px;
      }
    }
    .subTitle {
      color: white;
      margin-top: 20px;
      font-size: 15px;
      @media (min-width: 576px) {
        margin-top: 30px;
        font-size: 18px;
      }
      @media (min-width: 768px) {
        font-size: 18px;
      }
      @media (min-width: 992px) {
        font-size: 24px;
      }
      @media (min-width: 1200px) {
        margin-top: 55px;
        font-size: 24px;
      }
    }

    .button {
      margin-top: 20px;
      @media (min-width: 576px) {
        margin-top: 30px;
      }
      @media (min-width: 1200px) {
        margin-top: 65px;
      }
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;