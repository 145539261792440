.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 576px) {
    padding: 25px 0px;
  }
  @media (min-width: 576px) {
    padding: 25px 0px;
  }
  @media (min-width: 768px) {
    padding: 50px 0px;
  }
  @media (min-width: 992px) {
    padding: 50px 0px;
  }
  @media (min-width: 1200px) {
    padding: 50px 0px;
  }

  .title {
    font-size: 48px;
    font-weight: 600;
    text-align: center;

    @media (max-width: 576px) {
      font-size: 24px;
    }
    @media (min-width: 576px) {
      font-size: 24px;
    }
    @media (min-width: 768px) {
      font-size: 48px;
    }
    @media (min-width: 992px) {
      font-size: 48px;
    }
    @media (min-width: 1200px) {
      font-size: 48px;
    }
  }

  .text {
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;
    text-align: center;

    @media (max-width: 576px) {
      font-size: 16px;
    }
    @media (min-width: 576px) {
      font-size: 16px;
    }
    @media (min-width: 768px) {
      font-size: 24px;
    }
    @media (min-width: 992px) {
      font-size: 24px;
    }
    @media (min-width: 1200px) {
      font-size: 24px;
    }
  }

  .countDown {
    margin-top: 30px;
    padding: 20px 60px;
    display: inline-flex;
    justify-content: center;
    border: solid 1px;

    @media (max-width: 576px) {
      padding: 15px;
    }
    @media (min-width: 576px) {
      padding: 15px;
    }
    @media (min-width: 768px) {
      padding: 20px 30px;
    }
    @media (min-width: 992px) {
      padding: 20px 30px;
    }
    @media (min-width: 1200px) {
      padding: 20px 60px;
    }

    .countDownItem {
      display: inline-flex;
      align-items: flex-start;

      .separate {
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 2.83px;

        @media (max-width: 576px) {
          margin: 0 10px;
          font-size: 20px;
        }
        @media (min-width: 576px) {
          margin: 0 10px;
          font-size: 20px;
        }
        @media (min-width: 768px) {
          margin: 0 20px;
          font-size: 45px;
        }
        @media (min-width: 992px) {
          margin: 0 40px;
          font-size: 68px;
        }
        @media (min-width: 1200px) {
          margin: 0 40px;
          font-size: 68px;
        }
      }

      .numberDiv {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-width: 90px;

        @media (max-width: 576px) {
          min-width: 40px;
        }
        @media (min-width: 576px) {
          min-width: 40px;
        }
        @media (min-width: 768px) {
          min-width: 90px;
        }
        @media (min-width: 992px) {
          min-width: 90px;
        }
        @media (min-width: 1200px) {
          min-width: 90px;
        }

        .number {
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: 2.83px;

          @media (max-width: 576px) {
            font-size: 20px;
          }
          @media (min-width: 576px) {
            font-size: 20px;
          }
          @media (min-width: 768px) {
            font-size: 45px;
          }
          @media (min-width: 992px) {
            font-size: 68px;
          }
          @media (min-width: 1200px) {
            font-size: 68px;
          }
        }

        .numberText {
          margin-top: 10px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.56px;

          @media (max-width: 576px) {
            font-size: 10px;
          }
          @media (min-width: 576px) {
            font-size: 10px;
          }
          @media (min-width: 768px) {
            font-size: 20px;
          }
          @media (min-width: 992px) {
            font-size: 20px;
          }
          @media (min-width: 1200px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;