.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  word-wrap: break-word;

  @media (max-width: 576px) {
    padding: 0px;
  }
  @media (min-width: 576px) {
    padding: 0px;
  }
  @media (min-width: 768px) {
    padding: 0px 7.5px;
  }
  @media (min-width: 992px) {
    padding: 0px 22.5px;
  }
  @media (min-width: 1200px) {
    padding: 0px 22.5px;
  }

  .image {
    width: 120px;
    height: 120px;
    border-radius: 99px;
  }

  .title {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    margin: 25px 0px;
  }

  .subTitle {
    width: 100%;
    font-size: 18px;
    font-weight: 300;
  }

  .line {
    width: 100%;
    border-top: solid 1px #d3d3d3;
    margin: 15px 0px;
  }

  .text {
    width: 100%;
    font-size: 18px;
    font-weight: 300;
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;