.commonWidth {
  @media (max-width: 576px) {
    width: 100%;
    padding: 10px 15px;
  }
  @media (min-width: 576px) {
    width: 100%;
    padding: 10px 20px;
  }
  @media (min-width: 768px) {
    width: 100%;
    padding: 10px 20px;
  }
  @media (min-width: 992px) {
    width: 100%;
    padding: 10px 25px;
  }
  @media (min-width: 1200px) {
    width: 1200px;
    padding: 10px 25px;
  }
}

.bannerLinear {
  background: transparent;
}

.commonImageBanner {
  width: 100%;

  @media (max-width: 576px) {
    min-height: 265px;
  }
  @media (min-width: 576px) {
    min-height: 360px;
  }
  @media (min-width: 768px) {
    min-height: 468px;
  }
  @media (min-width: 992px) {
    min-height: 700px;
  }
  @media (min-width: 1200px) {
    min-height: 700px;
  }
}

.commonImageBackground {
  width: 100%;

  @media (max-width: 576px) {
    min-height: 265px;
  }
  @media (min-width: 576px) {
    min-height: 360px;
  }
  @media (min-width: 768px) {
    min-height: 468px;
  }
  @media (min-width: 992px) {
    min-height: 482px;
  }
  @media (min-width: 1200px) {
    min-height: 500px;
  }
}

.commonImage {
  width: auto;
  max-width: 100%;
  object-fit: cover;

  @media (max-width: 576px) {
    min-height: 265px;
  }
  @media (min-width: 576px) {
    min-height: 360px;
  }
  @media (min-width: 768px) {
    min-height: 468px;
  }
  @media (min-width: 992px) {
    min-height: 482px;
  }
  @media (min-width: 1200px) {
    min-height: 500px;
  }
}

.commonPaddingItem {
  @media (max-width: 576px) {
    padding: 16px 0;
  }
  @media (min-width: 576px) {
    padding: 16px 0;
  }
  @media (min-width: 768px) {
    padding: 32px 0;
  }
  @media (min-width: 992px) {
    padding: 50px 0;
  }
  @media (min-width: 1200px) {
    padding: 50px 0;
  }
}

.commonMainBackgroundColor {
  background: var(--background);
  @media (min-width: 1440px) {
    max-width: 1440px;
    margin: auto;
  }
}

.commonMainColorLight {
  background: var(--block);
}

.fontColor {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    color: var(--textColor);
  }

  color: var(--textColor);
}
