.box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  @media (min-width: 768px) {
    display: none;
  }
}

.itemBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 0 7px;
  width: 20%;
  border: 0;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  opacity: 0.7;

  &.active {
    opacity: 1;
  }
}

.itemText {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.41px;
  margin: 4px 0 0 0;
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;