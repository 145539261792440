.container {
  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
  }

  .subTitle {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .button {
    margin-top: 15px;
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;