@import '../../themeCommon.module.less';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .backVideo {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .icon {
    height: 50px;
    width: 50px;
    color: white;
    cursor: pointer;
  }
}

.bannerContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.contentColor {
  color: white;
}

.content {
  .commonWidth;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    align-items: center;
    justify-content: center;
  }

  .textDiv {
    margin-bottom: 60px;

    .title {
      font-weight: 500;

      @media (max-width: 576px) {
        font-size: 40px;
      }
      @media (min-width: 576px) {
        font-size: 30px;
      }
      @media (min-width: 768px) {
        font-size: 30px;
      }
      @media (min-width: 992px) {
        font-size: 60px;
      }
      @media (min-width: 1200px) {
        font-size: 60px;
      }
    }
    .subTitle {
      margin-top: 20px;
      margin-bottom: 15px;

      @media (max-width: 576px) {
        font-size: 24px;
      }
      @media (min-width: 576px) {
        font-size: 18px;
      }
      @media (min-width: 768px) {
        font-size: 18px;
      }
      @media (min-width: 992px) {
        font-size: 24px;
      }
      @media (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }
}

.mobileContent {
  .content;
  padding-top: 25px;
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;