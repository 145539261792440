@import '../../themeCommon.module.less';

.wrapper {
  aspect-ratio: 2880 / 1000;
  min-height: unset !important;
  .commonImageBackground;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .image {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-fit: contain;
  }

  .content {
    .commonWidth;
    position: relative;
    min-height: inherit;
    display: flex;
    align-items: center;

    .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        font-size: 30px;
        font-weight: 600;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      .subTitle {
        color: white;
        font-size: 15px;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 10px;
        @media (min-width: 768px) {
          font-size: 20px;
          margin-top: 20px;
          margin-bottom: 30px;
        }
        @media (min-width: 992px) {
          margin-top: 30px;
          margin-bottom: 60px;
        }
        @media (min-width: 1200px) {
          margin-top: 30px;
          margin-bottom: 60px;
        }
      }
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;