@import '../../../components/home/themeCommon.module.less';

.container {
  position: relative;

  .carousel {
    @media (max-width: 576px) {
      padding: 0 24px;
    }
    @media (min-width: 576px) {
      padding: 0 48px;
    }
  }

  .button {
    position: absolute;
    cursor: pointer;
    user-select: none;

    @media (max-width: 576px) {
      top: calc(50% - 14px);
    }
    @media (min-width: 576px) {
      top: calc(50% - 24px);
    }

    .buttonImage {
      @media (max-width: 576px) {
        height: 36px;
        width: 36px;
      }
      @media (min-width: 576px) {
        height: 48px;
        width: 48px;
      }
    }
  }

  .prevButton {
    .button;
    left: 0;
  }

  .nextButton {
    .button;
    right: 0;
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;