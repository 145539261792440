@import '../../../components/home/themeCommon.module.less';

.container {
  .item {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
    background-color: white;
    padding: 25px 20px;
    margin-top: 15px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
  }

  .addIcon {
    width: 16px;
    margin-left: auto;
  }

  .text {
    .fontColor;
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;

    p {
      margin-bottom: 0;
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;