.wrapper {
  .cardRow {
    justify-content: space-evenly;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      border-radius: 50%;

      @media (max-width: 576px) {
        width: 169px;
        height: 169px;
      }
      @media (min-width: 576px) {
        width: 212px;
        height: 212px;
      }
      @media (min-width: 768px) {
        width: 169px;
        height: 169px;
      }
      @media (min-width: 992px) {
        width: 146px;
        height: 146px;
      }
      @media (min-width: 1200px) {
        width: 188px;
        height: 188px;
      }
    }

    .imageSquare {
      object-fit: cover;
      height: auto;

      @media (max-width: 576px) {
        width: 100%;
      }
      @media (min-width: 576px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        max-width: 230px;
      }
      @media (min-width: 992px) {
        max-width: 285px;
      }
      @media (min-width: 1200px) {
        max-width: 285px;
      }
    }

    .textDiv {
      width: 100%;
      margin-top: 15px;
      padding: 15px;
      text-align: center;
      white-space: pre-line;
      word-wrap: break-word;
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;